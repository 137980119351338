.alignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.alignItems {
  justify-content: space-evenly;
}

.alignLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.alignRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logoPosition {
  width: 150%;
  height: 100%;
  position: relative;
  right: 30px;
}

.cardStyles {
  padding: 20px;
  margin: auto;
}

@media (max-width: 600px) {
  .cardStyles {
    width: 100vw;
    height: 100vh;
  }
}
.boxInfo {
  background-color: #f6f6f6;
  border-radius: 8px;
}
.sticky {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  height: 8%;
  z-index: 1;
}

.fade-enter {
  opacity: 0;
  transform: scale(0.95);
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms, transform 500ms;
}
.fade-exit {
  opacity: 1;
  transform: scale(1);
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 500ms, transform 500ms;
}
.logoEffect {
  transition: transform 0.3s ease-in-out;
}
.logoEffect:hover {
  transform: scale(1.15);
  cursor: pointer;
}
